// import { Timestamp } from 'firebase-admin/firestore';
import * as z from 'zod';
import { CommissionTypeEnum } from '../commission';
import { JfwDateAsTimestampSchema } from '../date';
import { DealerInfoSchema } from '../dealer/dealer-info.type';
import { LookSchema } from '../ensemble';
import { InStoreInfoSchema } from '../store/in-store-info.type';
import { EventAdminSchema } from './event-admin.type';
import { EventLastUpdatedBySchema } from './event-last-updated-by.type';
import { EventMemberSchema } from './event-member.type';
import { EventNameSchema } from './event-name.type';
import { EventTypeSchema } from './event-type.enum';

// const updateTimeSchema = z.instanceof(Timestamp);

export const EventSchema = z.object({
  id: z.string(),
  createdByUserId: z.string().optional(),
  dealerId: z.string().optional(),
  dealerInfo: DealerInfoSchema.optional(),
  commissionType: z.nativeEnum(CommissionTypeEnum).optional(),
  //commissionAccountNumber: z.string().optional(),
  createDate: z.number().optional(),
  lastUpdated: z
    .union([z.string(), z.number()]) // This was only string, but we are using Date.now() a lot, which is a number, so this will allow both, but always return a string
    .transform((val) => String(val))
    .optional(),
  lastUpdatedBy: EventLastUpdatedBySchema.optional(),
  /** a random uuid generated by the front end as a sort of tracking number to tell the difference between
   * an event doc received from the Firestore SDK immediately after making an update and the updated doc returned from the api.
   * This allows the front end to handle the race condition between which of those two event docs (both representing the same update) arrives first. */
  lastUpdateId: z.string().optional(),
  isOrganizer: z.boolean().optional(),
  isSingleUser: z.boolean().optional(),
  visible: z.boolean().optional(),
  eventType: EventTypeSchema,
  eventName: EventNameSchema,
  eventDate: JfwDateAsTimestampSchema,
  looks: z.array(LookSchema),
  members: z.array(EventMemberSchema),
  memberIds: z.array(z.string()).optional(),
  memberEmails: z.array(z.string()).optional(),
  admins: z.array(EventAdminSchema),
  adminEmails: z.array(z.string()).optional(),
  inStoreInfo: InStoreInfoSchema.optional(),
  saved: z.boolean().optional(),
  nationalEventId: z.string().optional(),
  styleCodes: z.array(z.string()).optional(),
  unlockDate: z.number().optional(),
  errorOnUpdate: z.boolean().optional(),
  update_time: z.any().optional(),
  legacyEventId: z.number().optional(),
  legacyTransferredAt: z.number().optional(),
  // update_time: updateTimeSchema.optional(), // try this again after Angular v17 upgrade (or maybe after Firebase upgrade past 11)
});

export type ZodEvent = z.infer<typeof EventSchema>;
export type ZodEventType = Omit<ZodEvent, ''>;

export interface IEvent extends ZodEvent { }

export type Event = z.infer<typeof EventSchema>;

// export type Event = {
//   id: string;
//   createdByUserId?: string;
//   dealerId?: string;
//   dealerInfo?: DealerInfo;
//   commissionType?: CommissionTypeEnum;
//   createDate?: number;
//   lastUpdated?: string;
//   isOrganizer?: boolean;
//   isSingleUser?: boolean;
//   visible?: boolean;
//   eventType: EventType;
//   eventName: string;
//   eventDate: JfwDateAsTimestamp;
//   looks: Look[];
//   members: EventMember[];
//   memberIds?: string[];
//   admins: EventAdmin[];
//   inStoreInfo?: InStoreInfo;
//   saved?: boolean;
//   nationalEventId?: string;
//   styleCodes?: string[];
//   unlockDate?: number;
//   errorOnUpdate?: boolean;
//   /** when reading the value it is a firestore Timestamp, but when assigning the value it is assigned using FieldValue.serverTimestamp() */
//   update_time?: any;
//   // update_time?: Timestamp;
// };
