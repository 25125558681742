import { z } from 'zod';

const DEBUG = {
  logAll: false,
}

/**
 * Date should be a number (Unix epoch timestamp in seconds).
 * Validates that the value is a number,
 * the number is positive,
 * the number is not more than 5 years in the future,
 * and the number is not in the past.
 *  */
export const JfwDateAsTimestampSchema = z.number({ invalid_type_error: "Please enter a valid timestamp" })
  .refine(val => {
    const isNegative = val < 0
    if (isNegative) {
      if (DEBUG.logAll) console.log('Timestamp is negative', { val });
      return false;
    }
    return true;
  }, { message: "Timestamp must be positive." })
  .refine(val => { // Check if date is in the past
    const today = new Date(Date.now());
    today.setHours(0, 0, 0, 0);
    const isInPast = val < Math.floor(today.getTime() / 1000);
    if (isInPast) {
      if (DEBUG.logAll) console.log('Event date is in the past', { today, val });
      return false;
    }
    return true;
  }, { message: "Timestamp must not be in the past." })
  .refine(val => { // Check if date is not more than 5 years in the future.  This will also catch dates provided in milliseconds instead of seconds.
    const futureDate = new Date(Date.now());
    futureDate.setFullYear(futureDate.getFullYear() + 5);
    futureDate.setHours(0, 0, 0, 0);
    const isFiveYearsOrMoreInFuture = val >= Math.floor(futureDate.getTime() / 1000);
    if (isFiveYearsOrMoreInFuture) {
      if (DEBUG.logAll) console.log('Event date is 5 years or more in the future', { val, futureDate });
      return false;
    }
    return true;
  }, { message: "Timestamp must not be 5 years or more in the future." })

/**
 * Number should be a Unix epoch timestamp in seconds.
 * It should be non-negative.
 * It should not be in the past.
 * It should not be more than 5 years in the future.
 */
export type JfwDateAsTimestamp = z.infer<typeof JfwDateAsTimestampSchema>;
