import * as z from 'zod';
import { EcomStyleSchema } from '../style/ecommerce/ecom-style.type';

export const LookSchema = z.object({
  /**unique ID for adding / editing / deleting within Event */
  id: z.string(),
  /** entered by marketing for ensemble, customer for look */
  title: z.string(),
  /** selected EcomStyles */
  styles: z.array(EcomStyleSchema),
  /** temporary, read only - not used for checkout */
  price: z.number().optional(),
  /** used for record keeping - jumping off point ensemble code */
  ensembleCode: z.string().optional(),
  /** used to keep track of styles in a Look/Ensemble (for firestore querying) */
  styleCodes: z.array(z.string()).optional(),
});

export type Look = z.infer<typeof LookSchema>;
// export type Look = {
//   id: string; // unique ID for adding/editing/deleting within Event
//   title: string; // entered by marketing for ensemble, customer for look
//   styles: EcomStyle[]; // selected EcomStyles
//   price?: number; // temporary, read only - not used for checkout
//   ensembleCode?: string; // used for record keeping - jumping off point ensemble code
//   styleCodes?: string[]; // used to keep track of styles in a Look/Ensemble (for firestore querying)
// }
