import * as z from 'zod';
import { ShareEventDetailsSchema } from '../event/share-event-details.type';
import { DealerAccountElasticSchema } from './dealer-account-elastic.type';

export const InStoreInfoSchema = z.object({
  isCreatedByDealer: z.boolean(),
  dealerId: z.string().optional(),
  // store: StoreSchema.optional(),
  store: DealerAccountElasticSchema.optional(),
  dateSharedWithStore: z.number().optional(),
  acceptedDate: z.number().optional(),
  rejectedDate: z.number().optional(),
  shareEventDetails: ShareEventDetailsSchema.optional(),
});

export type InStoreInfo = z.infer<typeof InStoreInfoSchema>;

// export type InStoreInfo = {
//   isCreatedByDealer: boolean;
//   dealerId?: string;
//   // store?: Store;
//   store?: DealerAccountElastic;
//   dateSharedWithStore?: number;
//   shareEventDetails?: ShareEventDetails;
// };
